import { boot } from 'quasar/wrappers';
import aws from '../aws-exports';
import { Auth, CookieStorage } from '@aws-amplify/auth';

const config = {
  aws_project_region: aws.region,
  aws_cognito_identity_pool_id: aws.identityPoolId,
  aws_cognito_region: aws.region,
  aws_user_pools_id: aws.userPoolId,
  aws_user_pools_web_client_id: aws.userPoolClientId,
  mandatorySignIn: true,
  oauth: {
    domain:
      process.env.NODE_ENV === 'development' ? 'localhost' : aws.authDomain,
    secure: true,
  },
  cookieStorage: {
    domain:
      process.env.NODE_ENV === 'development' ? 'localhost' : '.mes.condoor.com',
    secure: true,
  },
  Auth: {
    identityPoolId: aws.identityPoolId,
    region: aws.region,
    userPoolId: aws.userPoolId,
    userPoolWebClientId: aws.userPoolClientId,
  },
  Storage: new CookieStorage({
    domain:
      process.env.NODE_ENV === 'development' ? 'localhost' : '.mes.condoor.com',
    secure: true,
  }),
};
Auth.configure(config);

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $auth: typeof Auth;
  }
}

// "async" is optional;
// more info on params: https://v2.quasar.dev/quasar-cli/boot-files
export default boot(({ app } /* { app, router, ... } */) => {
  app.config.globalProperties.$auth = Auth;
});

export { Auth };
