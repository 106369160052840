const aws = {
  region: 'eu-west-1',
  identityPoolId: 'eu-west-1:434a8d9b-b3a8-4c2a-9e19-f86410746ceb',
  userPoolId: 'eu-west-1_zcwLaqzws',
  userPoolClientId: '3t52cjsg10oqrq2hgl2oltd8rg',
  authDomain: 'acc.auth.mes.condoor.com',
  mesDomain: 'acc.mes.condoor.com',
  userManagementAppsyncEndpoint: 'https://acc.auth.mes.condoor.com/graphql',
};
export default aws;
