import { boot } from 'quasar/wrappers';

// required
import '@fortawesome/fontawesome-pro/css/fontawesome.css';
import '@fortawesome/fontawesome-pro/css/light.css';
// do you want these too?
// import '@fortawesome/fontawesome-pro/css/thin.css'
// import '@fortawesome/fontawesome-pro/css/duotone.css'
// import '@fortawesome/fontawesome-pro/css/brands.css'
// import '@fortawesome/fontawesome-pro/css/solid.css'
// import '@fortawesome/fontawesome-pro/css/regular.css'

// "async" is optional;
// more info on params: https://v2.quasar.dev/quasar-cli/boot-files
export default boot(async (/* { app, router, ... } */) => {
  // something to do
});
