import { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      { name: 'login', path: '', component: () => import('pages/Login.vue') },
      {
        name: 'change-password',
        path: '/change-password',
        component: () => import('pages/ChangePassword.vue'),
      },
    ],
  },
  {
    path: '/forgot-password',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        name: 'forgot-password',
        path: '',
        component: () => import('pages/forgotPassword/index.vue'),
      },
    ],
  },

  {
    path: '/verification',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        name: 'verification',
        path: '',
        component: () => import('pages/verification/index.vue'),
      },
    ],
  },

  {
    path: '/mfa/verification',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        name: 'mfaVerification',
        path: '',
        component: () => import('pages/mfaVerification/index.vue'),
      },
    ],
  },
  {
    path: '/mfa/setup',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        name: 'mfaSetup',
        path: '',
        component: () => import('pages/mfaSetup/index.vue'),
      },
    ],
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    component: () => import('pages/Error404.vue'),
  },
];

export default routes;
